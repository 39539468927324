import Icons from "icons/sidebar";
import KanbanIcon from "../../icons/sidebar/Kanban";
import PagesIcon from "../../icons/sidebar/Pages";
import ProjectIcon from "../../icons/sidebar/Projects";
import SessionsIcon from "../../icons/sidebar/Sessions";
import DataTableIcon from "../../icons/sidebar/DataTable";
import ChatIcon from "../../icons/sidebar/Chat";
import UserManagementIcon from "../../icons/sidebar/UserManagement";
import CalendarIcon from "../../icons/sidebar/Calendar";
import UserProfileIcon from "../../icons/sidebar/UserProfile";

const index = [
    /*
  {
    title: "Dashboards",
    Icon: Icons.DashboardIcon,
    role: ['executor'],
    children: [
      { name: "Learning Management", path: "/dashboard", role: ['executor']  },
      { name: "Job Management", path: "/dashboard/job-management", role: ['executor']  },
      { name: "CRM", path: "/dashboard/crm", role: ['executor']  },
      { name: "Sales", path: "/dashboard/sales", role: ['executor']  },
      {
        name: "Sub Child",
        path: "/dashboard/sub-child",
        subChildren: [
          { name: "Sub Child V1", path: "/dashboard/sub-child-v1", role: ['executor']  },
          { name: "Sub Child V2", path: "/dashboard/sub-child-v2", role: ['executor']  },
          { name: "Sub Child V3", path: "/dashboard/sub-child-v3", role: ['executor']  },
        ],
      },
      { name: "Sales V2", path: "/dashboard/sales-v2", role: ['executor']  },
      { name: "SaaS", path: "/dashboard/saas", role: ['executor']  },
      { name: "Project Management", path: "/dashboard/project-management", role: ['executor']  },
      {
        name: "Project Management V2",
        path: "/dashboard/project-management-v2",
        role: ['executor']
      },
    ],
  },

     */

  { title: "Заявки", Icon: Icons.SessionsIcon, path: "/dashboard/offer-list", role: ['executor'] },
  { title: "Текущие клиенты", Icon: Icons.UserProfileIcon, path: "/dashboard/contract-list", role: ['executor'] },
  { title: "План занятий", Icon: Icons.CalendarIcon, path: "/dashboard/lessons-plan-report", role: ['executor'] },
  { title: "Учет занятий", Icon: Icons.UserManagementIcon, path: "/dashboard/class-schedule-report", role: ['executor'] },
  { title: "Расписание", Icon: Icons.DataTableIcon, path: "/dashboard/schedule", role: ['executor'] },
  // { title: "Lessons", Icon: Icons.DataTableIcon, path: "/dashboard/lesson-list", role: ['executor', 'customer'] },
  //{ title: "Teachers", Icon: Icons.UserProfileIcon, path: "/dashboard/user-grid", role: ['customer'] },
  { title: "Задать вопрос", Icon: Icons.ChatIcon, path: "/dashboard/ask-question", role: ['executor','customer'] },


    /*
  {
    title: "Teachers",
    Icon: Icons.UserProfileIcon,
    children: [
      { name: "Add User", path: "/dashboard/add-user" },
      { name: "User List", path: "/dashboard/user-list" },
      { name: "User List V2", path: "/dashboard/user-list-v2" },
      { name: "User Grid", path: "/dashboard/user-grid" },
      { name: "User Grid V2", path: "/dashboard/user-grid-v2" },
      { name: "Contact List", path: "/dashboard/contact-list" },
      { name: "Contact Grid", path: "/dashboard/contact-grid" },
    ],
  },

     */
  {
    title: "Invoice",
    Icon: Icons.InvoiceIcon,
    role: ['customer'],
    children: [
      { name: "Invoice List", path: "/dashboard/invoice-list", role: ['customer'] },
      //{ name: "Invoice List V2", path: "/dashboard/invoice-list-v2" },
      //{ name: "Invoice Details", path: "/dashboard/invoice-details" },
      { name: "Invoice Details V2", path: "/dashboard/invoice-details-v2", role: ['customer']  },
      //{ name: "Create Invoice", path: "/dashboard/create-invoice", role: ['executor']  },
      //{ name: "Create Invoice V2", path: "/dashboard/create-invoice-v2" },
    ],
  },
    /*
  {
    title: "Ecommerce",
    Icon: Icons.EcommerceIcon,
    role: ['executor'],
    children: [
      //{ name: "Shop", path: "/dashboard/shop" },
      { name: "Shop V2", path: "/dashboard/shop-v2", role: ['executor']  },
      //{ name: "cart", path: "/dashboard/cart" },
      { name: "Product Details", path: "/dashboard/product-details", role: ['executor']  },
      //{ name: "Checkout", path: "/dashboard/checkout" },
      //{ name: "Checkout V2", path: "/dashboard/checkout-v2" },
      //{ name: "Billing Address", path: "/dashboard/billing-address" },
      //{ name: "Payment", path: "/dashboard/payment" },
      //{ name: "Payment Complete", path: "/dashboard/payment-complete" },
      //{ name: "Payment Complete V2", path: "/dashboard/payment-complete-v2" },
    ],
  },
  {
    title: "Admin Ecommerce",
    Icon: Icons.AdminEcommerceIcon,
    children: [
      { name: "Product List", path: "/dashboard/product-list" },
      { name: "Product Grid", path: "/dashboard/product-grid" },
      { name: "Create Product", path: "/dashboard/create-product" },
      { name: "Order Management", path: "/dashboard/order-management" },
      { name: "Product Management", path: "/dashboard/product-management" },
      { name: "Customer Management", path: "/dashboard/customer-management" },
    ],
  },
     */
    /*
  {
    title: "Profiles",
    Icon: Icons.UserProfileIcon,
    children: [
      { name: "Profile", path: "/dashboard/profile" },
      { name: "Profile V2", path: "/dashboard/profile-v2" },
    ],
  },
    /*
  {
    title: "Projects",
    Icon: Icons.ProjectIcon,
    children: [
      { name: "Project List V1", path: "/dashboard/project-v1" },
      { name: "Project List V2", path: "/dashboard/project-v2" },
      { name: "Project List V3", path: "/dashboard/project-v3" },
      { name: "Team Member", path: "/dashboard/team-member" },
      { name: "Project Details", path: "/dashboard/project-details" },
    ],
  },

     */
    /*
  {
    title: "Accounts",
    Icon: Icons.AccountSettingsIcon,
    children: [
      { name: "Account", path: "/dashboard/account" },
      { name: "Account V2", path: "/dashboard/account-v2" },
    ],
  },
     */
  { title: "Настройки", Icon: Icons.AccountSettingsIcon, path: "/dashboard/account", role: ['executor', 'customer']  },

    /*
    { title: "DataTable", Icon: Icons.DataTableIcon, path: "/dashboard/data-table-v2" },
  { title: "Pricing", Icon: Icons.UserManagementIcon, path: "/dashboard/pricing" },
  { title: "Todo List", Icon: Icons.KanbanIcon, path: "/dashboard/todo-list" },
  { title: "Calendar", Icon: Icons.CalendarIcon, path: "/dashboard/calender" },

     */

  /*
  {
    title: "Chats",
    Icon: Icons.ChatIcon,
    children: [
      { name: "Chat V1", path: "/dashboard/chat-v1" },
      { name: "Chat V2", path: "/dashboard/chat-v2" },
    ],
  },
  */
  /*
  {
    title: "Pages",
    Icon: Icons.PagesIcon,
    children: [
      { name: "About", path: "/dashboard/about" },
      { name: "Contact", path: "/dashboard/contact" },
      { name: "Pricing", path: "/dashboard/pricing" },
      { name: "Privacy", path: "/dashboard/privacy" },
    ],
  },
  */
];

export default index;
