import axios from "../utils/axios";

export interface IOffice {
  id: string;
  title: string;
  enabled: boolean;
}

export const fetchAvailableOfficesForExecutor = async (): Promise<{ data: IOffice[] }> => {
  const data = await axios.get("/frontend-api/executor/office");

  return data.data;
};