import {FC, useEffect, useState} from "react";
import "./styles.css";
import {Box, Button, Card} from "@mui/material";
import InputMask from 'react-input-mask';
import {fetchExecutorContracts, IContract} from "../../../request/ContractRequest";
import {fetchAvailableOfficesForExecutor, IOffice} from "../../../request/OfficeRequest";
import EditLessonPlanConfirmation from "../components/EditLessonPlanConfirmation";
import {Edit} from "@mui/icons-material";
import { v4 as uuid } from 'uuid'
import {
    fetchPlanReport,
    IPlanReport,
    IPlanReportItem,
    IPlanReportItemLesson,
    saveReportCustomer
} from "../../../request/ReportPlanRequest";
import {format} from "date-fns";
import {useParams} from "react-router-dom";

const defaultPlanReportItem: IPlanReportItem = {
    customer: null,
    visitAmount: null,
    paymentAmount: null,
    lessons: [],
}

const defaultPlanReportItemLesson: IPlanReportItemLesson = {
    day: null,
    time: {
        startAt: null,
        endAt: null,
    },
    office: {
        id: null
    }
}

const DAYS = [
    {id: 'monday', title: 'Понедельник'},
    {id: 'tuesday', title: 'Вторник'},
    {id: 'wednesday', title: 'Среда'},
    {id: 'thursday', title: 'Четверг'},
    {id: 'friday', title: 'Пятница'},
    {id: 'saturday', title: 'Суббота'},
    {id: 'sunday', title: 'Воскресение'},
];

const sorterDayOfWeek = {
    "monday": 1,
    "tuesday": 2,
    "wednesday": 3,
    "thursday": 4,
    "friday": 5,
    "saturday": 6,
    "sunday": 7
}

const LessonsPlanReport: FC = () => {
    const {id} = useParams();
    const [offices, setOffices] = useState<IOffice[]|null>(null);
    const [executorContracts, setExecutorContracts] = useState<IContract[]|null>(null);

    const [nextContract, setNextContract] = useState<IContract|null>(null);
    const [currentContract, setCurrentContract] = useState<IContract|null>(null);
    const [currentPlanReportItem, setCurrentPlanReportItem] = useState<IPlanReportItem|null>(null);

    const [planReport, setPlanReport] = useState<IPlanReport|null>(null);
    const [editLessonPlanConfirmation, setEditLessonPlanConfirmation] = useState(false);

    useEffect(() => {
        (async () => {
            const { data } = await fetchAvailableOfficesForExecutor();
            setOffices([
                {id: 'at_teacher_home', title: 'У преподавателя дома', enabled: true},
                {id: 'at_student_home', title: 'У клиента на дому', enabled: true},
                {id: 'online', title: 'Online', enabled: true},
                ...data,
            ]);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const { data } = await fetchExecutorContracts();

            const items = data.sort(function (a, b) {
                if (a.customer.lastName < b.customer.lastName) {
                    return -1;
                }
                if (a.customer.lastName > b.customer.lastName) {
                    return 1;
                }
                return 0;
            })
            setExecutorContracts(items);
        })();
    }, []);

    useEffect(() => {
        if (!id) return

        (async () => {
            const data = await fetchPlanReport(`${id}`);
            setPlanReport(data)
        })();
    }, [id]);

    const onEditContract = (contract: IContract) => {
        setNextContract(contract)
        setEditLessonPlanConfirmation(true)
    }

    const onSaveLessonPlan = async () => {
        const res = await saveReportCustomer(
            planReport && planReport.id ? planReport?.id : uuid(),
            new Date(),
            currentPlanReportItem
        );

        // @ts-ignore
        setPlanReport(res)

        setCurrentContract(null)
        setCurrentPlanReportItem(null)
    }

    return <>
      <Box pt={2} pb={4}>
          <Card sx={{padding: 2, marginBottom: '20px'}} className='input-area'>

              <div className='input-area__fullname' style={{lineHeight: '25px'}}>
                  <div>
                      <strong>Период:</strong>
                      <span style={{ marginLeft: '10px', width: '150px'}}>
                          {planReport?.period ? format(new Date(+planReport?.period * 1000), "MM / yyyy") : '-'}
                      </span>
                  </div>

                  <div>
                      <strong>Дата последнего обновления:</strong>
                      <span style={{ marginLeft: '10px', width: '150px'}}>
                          {planReport?.updatedAt ? format(new Date(+planReport?.updatedAt * 1000), "dd.MM.yyyy HH:mm") : '-'}
                      </span>
                  </div>

                  {null !== currentContract ? <div >
                      <strong>Клиент:</strong>
                      <span style={{ marginLeft: '10px', width: '150px'}}>
                              {`${currentContract.customer.lastName} ${currentContract.customer.firstName ? currentContract.customer.firstName.charAt(0) + '.' : ''}`}
                          </span>
                  </div> : null}

                  {null !== currentContract ? <div style={{position: 'absolute', right: 0, top: '7px'}}>
                      <Button
                          onClick={() => setCurrentContract(null)}
                          variant="contained"
                          color="error"
                          size='small'
                          sx={{ height: '25px' }}
                          style={{ position: 'absolute', right: '140px'}}
                      >
                          Отмена
                      </Button>

                      <Button
                          variant="contained"
                          color="primary"
                          size='small'
                          sx={{ height: '25px' }}
                          style={{ position: 'absolute', right: '10px'}}
                          onClick={onSaveLessonPlan}
                      >
                          Сохранить
                      </Button>
                  </div> : null}
              </div>

              {null !== currentContract ? <>
                  <div style={{ marginTop: '25px', display: 'flex', marginBottom: '25px' }}>

                      <div style={{ width: '33.3%'}}>
                          <div>
                              <label>
                                  <span><strong>План посещений, часы</strong></span>
                                  <input
                                      name='visitPlan'
                                      style={{marginLeft: '10px', width: '150px'}}
                                      type='number'
                                      value={`${currentPlanReportItem?.visitAmount}`}
                                      onChange={
                                          (val) => {

                                              let item: IPlanReportItem = null !== currentPlanReportItem
                                                  ? currentPlanReportItem
                                                  : defaultPlanReportItem;

                                              if (!item.lessons?.length) {
                                                  item = {
                                                      ...item,
                                                      lessons: DAYS.map(day => ({...defaultPlanReportItemLesson, day: day.id}))
                                                  }
                                              }

                                              setCurrentPlanReportItem({
                                                  ...item,
                                                  customer: currentContract?.customer,
                                                  visitAmount: val.target.value
                                              })
                                          }}
                                  />
                              </label>
                          </div>
                      </div>

                      <div>
                          <div>
                              <label>
                                  <span><strong>Оплата</strong></span>
                                  <input
                                      name='paymentAmount'
                                      style={{marginLeft: '10px', width: '150px'}}
                                      type='number'
                                      value={`${currentPlanReportItem?.paymentAmount}`}
                                      onChange={
                                          (val) => {

                                              let item: IPlanReportItem = null !== currentPlanReportItem
                                                  ? currentPlanReportItem
                                                  : defaultPlanReportItem;

                                              if (!item.lessons?.length) {
                                                  item = {
                                                      ...item,
                                                      lessons: DAYS.map(day => ({...defaultPlanReportItemLesson, day: day.id}))
                                                  }
                                              }

                                              setCurrentPlanReportItem({
                                                  ...item,
                                                  customer: currentContract?.customer,
                                                  paymentAmount: val.target.value
                                              })
                                          }}
                                  />
                              </label>
                          </div>
                      </div>

                  </div>

                  <div style={{ display: 'flex', flexWrap: 'wrap', rowGap: '15px'}}>

                      {DAYS.map((day, index) =>
                          <div style={{ width: '33.3%' }} key={index}>
                              <div style={{width: '100px'}}>
                                  <strong>{day.title}</strong>
                              </div>

                              <div style={{ display: 'flex', gap: '3%'}}>
                                  <div style={{display: 'flex', flexDirection: 'column'}}>
                                      <label>
                                          Офис
                                      </label>
                                      <select
                                          defaultValue={`${currentPlanReportItem?.lessons?.find(item => (item?.day) === (day.id))?.office?.id}`}
                                          onChange={
                                              (val) => {

                                                  let item: IPlanReportItem = null !== currentPlanReportItem
                                                      ? currentPlanReportItem
                                                      : defaultPlanReportItem;

                                                  if (!item.lessons?.length) {
                                                      item = {
                                                          ...item,
                                                          lessons: DAYS.map(day => ({...defaultPlanReportItemLesson, day: day.id}))
                                                      }
                                                  }

                                                  // @ts-ignore
                                                  let lesson: IPlanReportItemLesson = item.lessons?.find(lesson => lesson.day === day.id)
                                                      ? item.lessons?.find(lesson => lesson.day === day.id)
                                                      : defaultPlanReportItemLesson

                                                  setCurrentPlanReportItem({
                                                      ...item,
                                                      customer: currentContract?.customer,
                                                      lessons: [].concat(
                                                          // @ts-ignore
                                                          item.lessons && item.lessons.length ? item.lessons?.filter(lesson => lesson.day !== day.id) : [],
                                                          [{...lesson, day: day.id, office: {id: val.target.value}}]
                                                      )
                                                  })
                                              }}
                                      >
                                          <option></option>
                                          {offices?.filter(office => office.enabled).map(office =>
                                              <option
                                                  key={office.id}
                                                  value={office.id}
                                              >
                                                  {office.title}
                                              </option>
                                          )}
                                      </select>
                                  </div>

                                  <div style={{display: 'flex', flexDirection: 'column'}}>
                                      <label >
                                          Время нач.
                                      </label>
                                      <InputMask
                                          mask="99:99"
                                          value={`${currentPlanReportItem?.lessons?.find(item => (item.day) === (day.id))?.time?.startAt}`}
                                          onChange={
                                              (val) => {

                                                  let item: IPlanReportItem = null !== currentPlanReportItem
                                                      ? currentPlanReportItem
                                                      : defaultPlanReportItem;

                                                  if (!item.lessons?.length) {
                                                      item = {
                                                          ...item,
                                                          lessons: DAYS.map(day => ({...defaultPlanReportItemLesson, day: day.id}))
                                                      }
                                                  }

                                                  // @ts-ignore
                                                  let lesson: IPlanReportItemLesson = item.lessons?.find(lesson => lesson.day === day.id)
                                                      ? item.lessons?.find(lesson => lesson.day === day.id)
                                                      : defaultPlanReportItemLesson

                                                  setCurrentPlanReportItem({
                                                      ...item,
                                                      customer: currentContract?.customer,
                                                      lessons: [].concat(
                                                          // @ts-ignore
                                                          item.lessons && item.lessons.length ? item.lessons?.filter(lesson => lesson.day !== day.id) : [],
                                                          [{...lesson, day: day.id, time: {...lesson.time, startAt: val.target.value}}]
                                                      )
                                                  })
                                              }}
                                      />
                                  </div>

                                  <div style={{display: 'flex', flexDirection: 'column'}}>
                                      <label>
                                          Время оконч.
                                      </label>

                                      <InputMask
                                          mask="99:99"
                                          value={`${currentPlanReportItem?.lessons?.find(item => (item.day) === (day.id))?.time?.endAt}`}
                                          onChange={
                                              (val) => {

                                                  let item: IPlanReportItem = null !== currentPlanReportItem
                                                      ? currentPlanReportItem
                                                      : defaultPlanReportItem;

                                                  if (!item.lessons?.length) {
                                                      item = {
                                                          ...item,
                                                          lessons: DAYS.map(day => ({...defaultPlanReportItemLesson, day: day.id}))
                                                      }
                                                  }

                                                  // @ts-ignore
                                                  let lesson: IPlanReportItemLesson = item.lessons?.find(lesson => lesson.day === day.id)
                                                      ? item.lessons?.find(lesson => lesson.day === day.id)
                                                      : defaultPlanReportItemLesson

                                                  setCurrentPlanReportItem({
                                                      ...item,
                                                      customer: currentContract?.customer,
                                                      lessons: [].concat(
                                                          // @ts-ignore
                                                          item.lessons && item.lessons.length ? item.lessons?.filter(lesson => lesson.day !== day.id) : [],
                                                          [{...lesson, day: day.id, time: {...lesson.time, endAt: val.target.value}}]
                                                      )
                                                  })
                                              }}
                                      />
                                  </div>

                              </div>
                          </div>
                      )}

                  </div>
              </> : null}


          </Card>


          <Card sx={{paddingTop: 3, paddingBottom: 3, paddingRight: 1, paddingLeft: 1}}>
              <div style={{ overflow: "scroll"}}>
                  <table className='pivot_table'>
                      <thead>
                      <tr>
                          <th className='main_th_cell'>
                              #
                          </th>
                          <th className='main_th_cell'>
                              ФИО
                          </th>
                          <th className='main_th_cell'>
                              Телефон
                          </th>
                          {DAYS.map(day =>
                              <th className='main_th_cell' key={day.id}>
                                  {day.title}
                              </th>)
                          }
                          <th className='main_th_cell'>
                              План посещений, часы
                          </th>
                          <th className='main_th_cell'>
                              Оплата
                          </th>
                      </tr>
                      <tr>
                          <th className='main_th_cell'>
                          </th>
                          <th className='main_th_cell'>
                          </th>
                          <th className='main_th_cell'>
                          </th>

                          {DAYS.map((day) => <th className='main_th_cell' key={day.id}>
                              <table>
                                  <tbody>
                                      <tr>
                                          <th className='nested_th_cell'>
                                              Время
                                          </th>
                                          <th className='nested_th_cell'>
                                              Офис
                                          </th>
                                      </tr>
                                  </tbody>
                              </table>
                          </th>)}

                          <th className='main_th_cell'>
                          </th>
                          <th className='main_th_cell'>
                          </th>
                      </tr>
                      </thead>

                      <tbody>
                        <>
                            {executorContracts?.length ? <>
                                {
                                    executorContracts.map((executorContract, index) => <tr
                                        onClick={() => onEditContract(executorContract)}
                                        style={{cursor: 'pointer'}}
                                        className={currentContract && currentContract.id === executorContract.id ? 'selected-contract' : undefined}
                                        key={index}
                                    >
                                        <td className='main_td_cell'>
                                            {index + 1}
                                            <Edit />
                                        </td>
                                        <td className='main_td_cell'>{`${executorContract.customer.lastName} ${executorContract.customer.firstName ? executorContract.customer.firstName.charAt(0) + '.' : ''}`}</td>
                                        <td className='main_td_cell'>{executorContract.customer.phone ? executorContract.customer.phone : '-'}</td>

                                        {planReport && planReport?.items?.find((planReportItem) => planReportItem?.customer?.id === executorContract.customer.id) ? <>
                                            {planReport?.items?.find((planReportItem) => planReportItem?.customer?.id === executorContract.customer.id)?.lessons?.sort(function (a, b) {
                                                // @ts-ignore
                                                let day1 = a?.day?.toLowerCase();
                                                let day2 = b?.day?.toLowerCase();
                                                // @ts-ignore
                                                return sorterDayOfWeek[day1] - sorterDayOfWeek[day2];
                                            }).map((value, index) => <td key={index} className='main_td_cell'>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td className='nested_td_cell'>
                                                                {value.time?.startAt ? value.time?.startAt : '-'} / {value.time?.endAt ? value.time?.endAt : '-'}
                                                            </td>
                                                            <td className='nested_td_cell'>
                                                                {offices?.find(office => office.id === value.office?.id)?.title}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>)}
                                        </>: <>
                                            {
                                                DAYS.map((value, index) => <td className='main_td_cell' key={index}>
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td className='nested_td_cell'>
                                                                -
                                                            </td>
                                                            <td className='nested_td_cell'>
                                                                -
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </td>)
                                            }
                                        </>}

                                        <td className='main_td_cell'>
                                            {planReport && planReport?.items?.find((planReportItem) => planReportItem?.customer?.id === executorContract.customer.id)?.visitAmount
                                                ? planReport?.items?.find((planReportItem) => planReportItem?.customer?.id === executorContract.customer.id)?.visitAmount
                                                : '-'
                                            }
                                        </td>
                                        <td className='main_td_cell'>
                                            {planReport && planReport?.items?.find((planReportItem) => planReportItem?.customer?.id === executorContract.customer.id)?.paymentAmount
                                                ? planReport?.items?.find((planReportItem) => planReportItem?.customer?.id === executorContract.customer.id)?.paymentAmount
                                                : '-'
                                            }
                                        </td>
                                    </tr>)
                                }
                            </> : null}
                        </>
                      </tbody>
                  </table>
              </div>
          </Card>
      </Box>
        <EditLessonPlanConfirmation
            open={editLessonPlanConfirmation}
            onClose={() => setEditLessonPlanConfirmation(false)}
            contract={nextContract}
            onSuccess={() => {
                setCurrentContract(nextContract)
                const planReportItem = planReport && planReport?.items?.find(
                    (planReportItem) => planReportItem?.customer?.id === nextContract?.customer?.id
                )
                setCurrentPlanReportItem(planReportItem ? planReportItem : null)
                setNextContract(null)
                setEditLessonPlanConfirmation(false)
            }}
        />
  </>
};

export default LessonsPlanReport;
