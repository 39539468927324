import axios from "../utils/axios";

export interface IPlanReport {
  id?: string|null;
  updatedAt?: string|null;
  period?: number|null;
  canEdit: boolean;
  items?: IPlanReportItem[]|null;
}

export interface IPlanReportItem {
  customer: {
    id: string;
    phone?: string|null;
  }|null,
  visitAmount?: string|null;
  paymentAmount?: string|null;
  lessons?: IPlanReportItemLesson[]|null;
}

export interface IPlanReportItemLesson {
  day: string|null;
  time: {
    startAt: string|null;
    endAt: string|null;
  },
  office: {
    id: string|null;
  }|null;
}

export const saveReportCustomer = async (
    reportId: string|null,
    period: Date,
    values: IPlanReportItem|null
): Promise<{}> => {
  const data = await axios.patch(`/frontend-api/plan-report/${reportId}/lesson`, {
    customerId: values?.customer?.id,
    period: Math.floor(period.getTime() / 1000),
    paymentAmount: values?.paymentAmount,
    visitAmount: values?.visitAmount,
    lessons: values?.lessons?.map(lesson => ({
      day: lesson.day,
      startAt: lesson?.time?.startAt,
      endAt: lesson?.time?.endAt,
      officeId: lesson.office?.id,
    }))
  });

  return data.data.data;
};

export const fetchPlanReportList = async (page: number): Promise<{ pages: number; data: IPlanReport[], meta: {canCreate: boolean } }> => {
  const data = await axios.get(`/frontend-api/plan-report`, {
    params: { page}
  });

  return data.data;
};

export const fetchPlanReport = async (id: string): Promise<IPlanReport> => {
  const data = await axios.get(`/frontend-api/plan-report/${id}`);

  return data.data.data;
};

export const createPlanReport = async (): Promise<IPlanReport> => {
  const data = await axios.post(`/frontend-api/plan-report`);

  return data.data;
};