import {
  Button,
  Grid,
  styled,
} from "@mui/material";
import AppModal from "components/AppModal";
import FlexBox from "components/flexbox/FlexBox";
import { H2 } from "components/Typography";
import {FC, SyntheticEvent} from "react";
import {IContract} from "../../../request/ContractRequest";

// component props interface
interface Props {
  open: boolean;
  onClose: () => void;
  contract: IContract|null;
  onSuccess: () => void;
}

// styled components
const StyledAppModal = styled(AppModal)(({ theme }) => ({
  maxWidth: 700,
  minWidth: 300,
  outline: "none",
  padding: "1.5rem",
}));

const EditLessonPlanConfirmation: FC<Props> = ({
 open,
 contract,
 onClose,
 onSuccess
}) => {
  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    onSuccess();
  }

  return (
    <StyledAppModal open={open} handleClose={onClose}>
      <H2 marginBottom={2}>
        Начать редактирование плана работы клиента "{`${contract?.customer?.lastName} ${contract?.customer?.firstName ? contract?.customer?.firstName?.charAt(0) + '.' : ''}`}"?
      </H2>

      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <FlexBox justifyContent="flex-end" gap={2} marginTop={2}>
              <Button fullWidth variant="outlined" onClick={onClose}>
                Нет
              </Button>
              <Button fullWidth type="submit" variant="contained">
                Да
              </Button>
            </FlexBox>
          </Grid>
        </Grid>
      </form>
    </StyledAppModal>
  );
};

export default EditLessonPlanConfirmation;
