import axios from "../utils/axios";
import {ICustomer} from "./CustomerRequest";

export enum ContractStatus {
  PENDING  = 'pending',
  ACTIVE   = 'active',
  PAUSED   = 'paused',
  CANCELED = 'canceled',
}

export interface IContract {
  id: string;
  customer: ICustomer;
  status: ContractStatus;
  address: string;
  phone: number;
  placeOfLesson: string;
  createdAt: string;
  updatedAt: string;
  startedAt: number;
  endedAt: number;
}

export const fetchList = async (search: string|null, page: number): Promise<{ pages: number; data: IContract[] }> => {
  const data = await axios.get(`/frontend-api/contract`, {
    params: {
      page,
      search
    },
  });

  return data.data;
};

export const fetchExecutorContracts = async (): Promise<{ pages: number; data: IContract[] }> => {
  const data = await axios.get(`/frontend-api/contract/choices`);

  return data.data;
};

export const fetchContract = async (contractId: string): Promise<{data: IContract }> => {
  const data = await axios.get(`/frontend-api/contract/${contractId}`);

  return data.data;
};